import {
  IVideoAutoplay,
  MultiUseHeroBanner
} from '@gemini/brand/el/ui/templates/multi-use-hero-banner';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldFile,
  ITemplateFieldHeadlineContentGroup,
  ITemplateFieldRadio,
  ITemplateFieldSelect,
  ITemplateFieldString,
  ITemplateFieldVideoContent,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import styles from './hero-banner.module.scss';

export interface IHeroBannerData {
  bgLarge: ITemplateFieldFile;
  bgSmall: ITemplateFieldFile;
  brandSection: ITemplateFieldSelect;
  disableLazyload: ITemplateFieldCheckbox;
  headlineContent: ITemplateFieldHeadlineContentGroup;
  imageAltText: ITemplateFieldString;
  loopingVidDesktop: ITemplateFieldString;
  loopingVidMob: ITemplateFieldString;
  loopingVidVolColor: ITemplateFieldRadio;
  loopingVidVolHideShow: ITemplateFieldRadio;
  moduleLink: ITemplateFieldString;
  templateLabelname: ITemplateFieldString;
  templateStyle: ITemplateFieldSelect;
  videoAutoplay: IVideoAutoplay;
  videoContent: ITemplateFieldVideoContent;
}

/*
  DRUPAL MUSTACHE: esteelauder\modules\custom\esteelauder_common\templates\styleguide_templates\styleguide_multi_use_mpp_v1\styleguide_multi_use_mpp_v1.mustache
  MAPPING: esteelauder\modules\custom\esteelauder_common\includes\templates.inc
*/

export interface IHeroBannerProps extends TemplateType {
  data: IHeroBannerData;
  dataMobile: IHeroBannerData | null;
  index: number;
}

export function HeroBanner({ data, dataMobile }: IHeroBannerProps) {
  return (
    <>
      {dataMobile && (
        <div className="block md:hidden">
          <MultiUseHeroBanner {...dataMobile} classes={styles['hero-banner']} />
        </div>
      )}
      <div className={`${dataMobile ? 'hidden md:block' : ''}`}>
        <MultiUseHeroBanner
          {...data}
          classes={styles['hero-banner']}
          isDesktop
        />
      </div>
    </>
  );
}

export default HeroBanner;
